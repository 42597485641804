import { observer } from "mobx-react";
import React, { useEffect } from "react";
import {
	currencyMaskPipe,
	formatStringToCurrency,
	getPhotoUrl,
	useStores,
} from "../../utils";
import { useNavigate } from "react-router-dom";
import { tg } from "../../index";
import { DateTime } from "luxon";

import styles from "./index.module.sass";
import { currencies } from "../Transfer";
import { Button } from "../../components/Button";
import { PlusIcon } from "../../components/Icons/PlusIcon";
import { Arrow } from "../../components/Icons";
import { Pin } from "../../components/Pin";
import { CurrenyType } from "../../stores";
import { Skeleton } from "../../components/Skeleton/Skeleton";

const euroRate = 60;
const dollarRate = 55;

const getSum = (currency: CurrenyType, sum) => {
	if (currency === "$") {
		return (sum / dollarRate).toLocaleString("ru-RU", {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});
	} else if (currency === "€") {
		return (sum / euroRate).toLocaleString("ru-RU", {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});
	} else {
		return sum.toLocaleString("ru-RU");
	}
};

export const Home = observer(() => {
	const {
		userStore: {
			userData,
			createSession,
			getUserData,
			getUserOperations,
			userOperations,
			currencySign,
			setCurrencySign,
			authorized,
		},
	} = useStores();
	const navigate = useNavigate();

	useEffect(() => {
		createSession(tg)
			.then(() => {
				getUserData({
					id: tg?.initDataUnsafe?.user?.id,
					queryId: tg?.initDataUnsafe?.query_id,
				});
			})
			.then(() => {
				getUserOperations(
					tg?.initDataUnsafe?.user?.id,
					tg?.initDataUnsafe?.query_id
				);
			});
	}, []);

	if (!authorized) {
		return <Pin />;
	}

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<img
					src={getPhotoUrl(tg?.initDataUnsafe?.receiver?.photo_url)}
					alt=""
				/>

				<span>{tg?.initDataUnsafe?.user?.first_name}</span>
			</div>

			<div className={styles.body}>
				<div className={styles.body_header}>
					<span>Баланс кошелька</span>
				</div>

				{userData ? (
					<div className={styles.body_sum}>
						<div className={styles.body_sum__currency}>
							{currencySign}
						</div>

						<div className={styles.body_sum__sum}>
							{
								getSum(currencySign, userData?.sum)
							}
						</div>
					</div>
				) : (
					<div className={styles.body_sum}>
						<Skeleton width={200} height={57} />
					</div>
				)}

				<div className={styles.body_buttons}>
					{currencies.map((button) => (
						<Button
							theme={
								currencySign === button.sign
									? "primary"
									: "muted"
							}
							onClick={() => setCurrencySign(button.sign)}
						>
							{button.label}
						</Button>
					))}
				</div>

				<div className={styles.body_refill}>
					<button onClick={() => navigate("/refill")}>
						<div className={styles.body_refill__icon}>
							<PlusIcon
								fill={tg?.themeParams?.button_text_color}
							/>
						</div>

						<span>Пополнить кошелек</span>
					</button>
				</div>

				<div className={styles.body_history}>
					<div className={styles.body_history__header}>
						<div className={styles.title}>История операций</div>

						<div className={styles.button}>
							<Arrow fill={tg?.themeParams?.hint_color} />
						</div>
					</div>
					 
					{userOperations &&
						userOperations.map((item, index) => (
							<div className={styles.history__item} key={index}>
								<div className={styles.history__item__left}>
									<div
										className={
											styles.history__item__receiver
										}
									>
										{item.receiver.displayName}
									</div>
									<div className={styles.history__item__sum}>
										{item.type === "TRANSFER" && item.receiver?.userId === userData?.userId && "+"}
										{item.type === "TRANSFER" && item.receiver?.userId !== userData?.userId && "-"}
										{item.type === "REFILL" && "+"}
										{item.sum.toLocaleString("ru-RU", {
											style: "currency",
											currency: "RUB",
											minimumFractionDigits: 0,
											maximumFractionDigits: 0,
										})}
									</div>
								</div>
								<div className={styles.history__item__photo}>
									{item.type === "REFILL" ? (
										<img src="/assets/vtb-logo.svg" />
									) : (
										<img
											src={getPhotoUrl(
												item.receiver.photoUrl
											)}
										/>
									)}
								</div>
							</div>
						))}
				</div>
			</div>
		</div>
	);
});
