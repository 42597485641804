import {tg} from "../../index";
import styles from './index.module.sass'
import {VFC} from "react";
import { getPhotoUrl } from '../../utils';

interface Props {
	type: 'from' | 'to'
}

export const ReceiverAvatar:VFC<Props> = ({ type }: Props) => {
	return (
		<div className={styles.transferTarget}>
			<div className={styles.transferTarget__photo}>
				<img src={getPhotoUrl(tg?.initDataUnsafe?.receiver?.photo_url)} alt=""/>
			</div>
			
			<div className={styles.transferTarget__text}>
				<div>Перевод {type === 'from' && 'от'}</div>
				<img src="/assets/telegram16-icon.svg" alt=""/>
				<div>{tg?.initDataUnsafe?.receiver?.first_name}</div>
			</div>
		</div>
	)
}