export const Arrow = (props) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M7.08331 4.16732L12.9166 10.0007L7.08331 15.834"
			stroke={props.fill}
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

export const ArrowLeft = (props) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_336_14883)">
			<path
				d="M14.4 18.6004L7.80002 12.0004L14.4 5.40039"
				stroke={props.fill}
				stroke-width="1.8"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_336_14883">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(24 24) rotate(-180)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export const ArrowRight = (props) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8 4.5L13.5 10L8 15.5"
			stroke={props.fill || "#707579"}
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);
