import React, {ReactNode} from "react";
import styles from "../../pages/Refill/refill.module.sass";

interface Props {
	top: ReactNode
	bottom: ReactNode
}

export const BorderedBlock = ({top, bottom}: Props) => {
	
	return (
		<div className={styles.inputBlock}>
			<div className={styles.inputBlock__sum}>
				<span>{top}</span>
				<span>{bottom} ₽</span>
			</div>
		</div>
	)
}
