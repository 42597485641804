import { MainStore } from "../stores";
import { MobXProviderContext } from "mobx-react";
import { useContext } from "react";
import IMask from "imask";

export function useStores(): MainStore {
	return <MainStore>useContext(MobXProviderContext);
}

export const currencyMask = IMask.createMask({
	mask: Number,
	scale: 0,
	signed: true,
	thousandsSeparator: " ",
	min: 0,
});

export const currencyMaskPipe = IMask.createPipe({
	mask: Number,
	signed: true,
	thousandsSeparator: " ",
});

export const formatCurrencyToNumber = (currency: number | string): number => {
	const trimmed = String(currency).replaceAll(" ", "");

	return +trimmed;
};

export const formatStringToCurrency = (currency: number): string => {
	const formatted = currencyMaskPipe(String(currency));

	return formatted;
};

export const hexToRgb = (hex: string): string => {
	const bigint = parseInt(hex?.substring(1), 16);
	const r = (bigint >> 16) & 255;
	const g = (bigint >> 8) & 255;
	const b = bigint & 255;

	return `${r}, ${g}, ${b}`;
};

export const getTransferCommission = (sum: number | string) => {
	return (formatCurrencyToNumber(sum) / 100) * 2;
};

export const getPhotoUrl = (url: string) => {
	return "/assets/user_avatar.png";
};
