import { observer } from "mobx-react";
import styles from "./transfer-details.module.sass";
import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { getPhotoUrl, getTransferCommission, useStores } from "../../utils";
import { tg } from "../../index";
import { OperationType } from "../../services";
import { Spinner } from "../../components/Spinner";
import { SuccessIcon } from "../../components/Icons";
import { CurrencyBlock } from "../../components/CurrencyBlock";
import { BorderedBlock } from "../../components/BorderedBlock";
import { Container } from "../../components/Container";

export const TransferDetails = observer(() => {
	const {
		userStore: { sum, currencySign, operation },
	} = useStores();
	const navigate = useNavigate();

	const [confirm, setConfirm] = useState(false);
	const [loader, setLoader] = useState(false);
	const [date, setDate] = useState("");

	useEffect(() => {
		const today = new Date();

		setDate(DateTime.now().toFormat("d MMMM, HH:mm", { locale: "ru" }));
	}, []);

	useEffect(() => {
		confirm
			? tg?.MainButton.setParams({
					text: `Закрыть`,
			  })
			: tg?.MainButton.setParams({
					text: `Подтвердить`,
			  });
	}, [confirm]);

	useEffect(() => {
		confirm
			? tg?.onEvent("mainButtonClicked", () => {
					tg.HapticFeedback.impactOccurred("heavy");
					operation(tg, OperationType.Transfer);
			  })
			: tg?.onEvent("mainButtonClicked", () => {
					tg.HapticFeedback.impactOccurred("heavy");
					setConfirm(true);
			  });
	}, [confirm]);

	useEffect(() => {
		setLoader(true);
		setTimeout(() => setLoader(false), 1000);
	}, [confirm]);

	return (
		<Container>
			<div className={styles.header}>
				<button onClick={() => navigate("/transfer")}>
					<img src="/assets/arrow-left.png" alt="" />
					<div className={styles.header__title}>Отправить</div>
				</button>
			</div>

			<div className={styles.body}>
				<CurrencyBlock
					sum={sum?.transferSum}
					sign={currencySign}
					suffix={
						<>
							Комиссия составит{" "}
							{getTransferCommission(sum?.transferSum)} ₽
						</>
					}
				/>

				<p className={styles.body__label}>Откуда</p>

				<div className={styles.user}>
					<div className={styles.userData}>
						<div className={styles.title}>Кошелек</div>

						<div className={styles.semibold}>
							{sum.transferSum} {currencySign}
						</div>
					</div>
				</div>

				<p className={styles.body__label}>Куда</p>
				<div className={styles.user}>
					<div className={styles.userData_withAvatar}>
						<img
							className={styles.user__avatar}
							src={getPhotoUrl(
								tg?.initDataUnsafe?.receiver?.photo_url
							)}
							alt=""
						/>
						<div className={styles.userData}>
							<div className={styles.semibold}>
								{tg?.initDataUnsafe?.receiver?.first_name}
							</div>

							<div className={styles.title}>Контакт Telegram</div>
						</div>
					</div>
				</div>
			</div>

			<div className={styles.footer}>
				{confirm ? (
					<>
						{loader ? (
							<>
								<Spinner />
							</>
						) : (
							<>
								<div className={styles.footer_success}>
									<div
										className={styles.footer_success__icon}
									>
										<SuccessIcon fill="#fff" />
									</div>

									<div
										className={styles.footer_success__text}
									>
										Перевод отправлен
									</div>

									<div
										className={styles.footer_success__date}
									>
										{date}
									</div>
								</div>

								<div className={styles.footer_questions}>
									Остались вопросы? Напишите{" "}
									<span>@TalkBank</span>
								</div>
							</>
						)}
					</>
				) : (
					<div className={styles.footer__info}>
						<img src="/assets/sbp.svg" alt="" />

						<span>Отправим через СБП</span>

						<img src="/assets/info.svg" alt="" />
					</div>
				)}
			</div>
		</Container>
	);
});
