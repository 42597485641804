import styles from "./refill.module.sass";
import { Arrow } from "../../components/Icons";
import { tg } from "../../index";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { formatStringToCurrency, useStores } from "../../utils";
import { observer } from "mobx-react";

export const RefillDetails = observer(() => {
	const {
		userStore: { userData, sum, cardNumber },
	} = useStores();
	const navigate = useNavigate();

	useEffect(() => {
		tg?.MainButton.setParams({
			text: "Пополнить кошелек",
		});

		tg?.MainButton.show();

		tg?.onEvent("mainButtonClicked", () => {
			tg.HapticFeedback.impactOccurred("heavy");
			navigate("/bank");
		});

		return () => tg?.MainButton.hide();
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<button onClick={() => navigate("/refill")}>
					<img src="/assets/arrow-left.png" alt="" />
					<div className={styles.header__title}>Пополнение</div>
				</button>
			</div>

			<div className={styles.body}>
				<div className={styles.body_header}>
					<div className={styles.body_header__title}>
						Детали операции
					</div>
					<div className={styles.body_header__link}>
						Редактировать
					</div>
				</div>

				<div className={styles.body__label}>Откуда</div>
				<div className={styles.inputBlock}>
					<div className={styles.inputBlock__refillSum}>
						{sum.refillSum} ₽
					</div>

					<div className={styles.inputBlock__suffix}>
						<span>*{cardNumber.slice(15, 19)}</span>

						<div>
							<img src="/assets/vtb-stripes.svg" alt="" />
						</div>
					</div>
				</div>

				<p className={styles.body__label}>Куда</p>
				<div className={styles.inputBlock}>
					<div className={styles.inputBlock__sum}>
						<span>Кошелек</span>
						<span>{userData.sum} ₽</span>
					</div>
				</div>
			</div>
		</div>
	);
});
