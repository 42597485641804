import UserStore from "./UserStore";
import {makeAutoObservable} from "mobx";

class MainStore {
	userStore: UserStore;

	constructor() {
		this.userStore = new UserStore(this)

		makeAutoObservable(this)
	}
}

const mainStore = new MainStore();
export default mainStore;
export { MainStore };