import { observer } from "mobx-react";
import cn from "classnames";
import { TextInput } from "@technokratos/components-react";
import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { tg } from "../../index";
import { currencyMask, formatStringToCurrency, useStores } from "../../utils";
import { Arrow } from "../../components/Icons";

import styles from "./refill.module.sass";

export const Refill = observer(() => {
	const {
		userStore: { userData, setSum, sum, cardNumber, setCardNumber },
	} = useStores();
	const navigate = useNavigate();

	const validateButton = useMemo(() => {
		return (
			!Boolean(cardNumber) ||
			cardNumber?.length < 19 ||
			sum?.refillSum < 1
		);
	}, [cardNumber, sum]);

	useEffect(() => {
		tg?.MainButton.setParams({
			text: "Отправить",
		});

		tg?.onEvent("mainButtonClicked", () => {
			tg.HapticFeedback.impactOccurred("heavy");
			navigate("/refill-details");
		});
	}, []);

	useEffect(() => {
		validateButton && tg?.MainButton.hide();
		!validateButton && tg?.MainButton.show();

		return () => tg?.MainButton.hide();
	}, [validateButton]);

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<button onClick={() => navigate("/")}>
					<img src="/assets/arrow-left.png" alt="" />
					<div className={styles.header__title}>Пополнение</div>
				</button>
			</div>

			<div className={styles.body}>
				<div className={styles.body__label}>Откуда</div>
				<TextInput
					className={cn(cardNumber?.length > 4 && styles.vtbInput)}
					suffix={
						cardNumber?.length > 4 && (
							<img src="/assets/vtbCard.svg" />
						)
					}
					mask="0000 0000 0000 0000"
					label="Номер карты"
					value={cardNumber}
					onChange={(e) => setCardNumber(e)}
				/>

				<p className={styles.body__label}>Куда</p>
				<TextInput
					disabled
					value={`${formatStringToCurrency(userData?.sum)} ₽`}
					label="Кошелек"
				/>

				<TextInput
					label="Сумма, ₽"
					mask={currencyMask}
					onChange={(e) => setSum("refillSum", e)}
					value={sum.refillSum}
				/>

				<div className={styles.body__description}>
					Комиссия за онлайн-перевод денег будет расчитана
					автоматически <br />
					<br />
					Нажимая «Пополнить», вы соглашаетесь с условиями публичной
					оферты
				</div>
			</div>
		</div>
	);
});
