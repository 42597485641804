import {FC, ReactNode, useMemo} from "react";
import {useThemeVariables} from "../../styles/theme-variables";
import {tg} from "../../index";
import cn from 'classnames';

import styles from './index.module.sass'
import './index.module.sass'

type ButtonTheme =
    | 'primary'
    | 'muted'

interface Props {
    children: ReactNode,
    theme?: ButtonTheme,
    className?: string;
    [key: string]: any;
}

export const Button: FC<Props> = ({
    children,
    theme = 'muted',
    className,
    ...props
}: Props) => {
    const { themeVariables } = useThemeVariables()

    const backgroundColor = useMemo(() => {
        switch (theme) {
            case 'primary':
                return tg?.themeParams.button_color
            case 'muted':
                return themeVariables.buttonBackground
            default:
                return null
        }
    }, [theme])

    return (
        <button
            className={cn(styles.button, `button__${theme}`)}
            style={{ backgroundColor }}
            {...props}
        >
            {children}
        </button>
    )
}