import React from 'react';
import ReactDOM from 'react-dom/client';
import {App} from './App';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "mobx-react";
import mainStore from './stores/MainStore';

import './styles.sass'

// @ts-ignore
export const tg = window.Telegram.WebApp

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
      <BrowserRouter>
          <Provider {...mainStore}>
              <App />
          </Provider>
      </BrowserRouter>
);
