import styles from "./index.module.sass";
import { useEffect } from "react";
import { observer } from "mobx-react";
import { tg } from "../../index";
import { walletsDone } from "../../services";

export const WalletAdd = observer(() => {
	useEffect(() => {
		tg?.MainButton.setParams({
			text: "Понятно",
		});
		tg?.MainButton?.show();

		tg?.onEvent("mainButtonClicked", () => {
			walletsDone(tg?.initDataUnsafe?.query_id).then(() => {
				tg?.close();
			});
		});
	}, []);

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<div className={styles.title}>Кошелек успешно добавлен</div>

				<div className={styles.description}>
					Теперь вы можете вызвать бота @BankBot прямо из меню
					вложений в чатах с другими пользователями
				</div>
			</div>
		</div>
	);
});
