import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { useStores } from "../../utils";

import styles from "./index.module.sass";
import { tg } from "../../index";
import { OperationType } from "../../services";
import {DateTime} from "luxon";

export const Bank = observer(() => {
	const {
		userStore: { operation, sum, getUserData, setSum, userData, cardNumber },
	} = useStores();
	const navigate = useNavigate();

	const [code, setCode] = useState<string>("");

	const handleOperation = () => {
		operation(tg, OperationType.Refill)
			.then(() => navigate("/"))
			.then(() => setSum("refillSum", null))
			.then(() => setSum("transferSum", null))
			.then(() => setCode(""))
			.then(() => {
				getUserData({
					id: tg?.initDataUnsafe?.user?.id,
					queryId: tg?.initDataUnsafe?.query_id,
				});
			});
	};

	const inputRef = useRef<HTMLInputElement>(null);

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<button onClick={() => navigate("/refill-details")}>
					<img src="/assets/arrow-left.png" alt="arrow-left" />
				</button>
				<div className={styles.header__title}>Пополнение</div>
			</div>

			<div className={styles.bank_header}>
				<img
					src="/assets/vtb-logo.svg"
					alt="vtb-logo"
					className={styles.vtbLogo}
				/>
				<img
					src="/assets/SecureCode.svg"
					alt="secure-core"
					className={styles.secureLogo}
				/>
			</div>

			<div className={styles.center}>
				<div className={styles.title}>
					Подтверждение оплаты на сумму
				</div>
				<div className={styles.amount}>{sum.refillSum} ₽</div>
				<div className={styles.enterCode}>Введите код из SMS </div>
				<div className={styles.number}>
					Отправлен на номер +{userData?.phone?.slice(0, 4)} *** ** {userData?.phone?.slice(-2)}
				</div>

				<form
					onSubmit={(event) => {
						event.preventDefault();
						event.stopPropagation();
						inputRef.current.blur();
					}}
				>
					<input
						ref={inputRef}
						className={styles.code}
						value={code}
						onChange={(e) => setCode(e.target.value)}
					/>
				</form>
			</div>

			<div className={styles.padding}>
				<div className={styles.codeExpire}>
					<div className={styles.timer}>
						<span className={styles.timeExpire}>
							SMS код действует 5 минут
						</span>
						<span className={styles.resend}>Отправить заново</span>
					</div>
				</div>

				<div className={styles.transInfo}>
					<div className={styles.row}>
						<div>Магазин:</div>
						<div>Bank Card2Card</div>
					</div>
					<div className={styles.row}>
						<div>Дата:</div>
						<div>{DateTime.now().toFormat("dd.MM.yyyy")}</div>
					</div>
					<div className={styles.row}>
						<div>Номер карты:</div>
						<div>ХХХХ ХХХХ ХХХХ {cardNumber?.slice(-4)}</div>
					</div>
				</div>
			</div>

			<button
				className={styles.send}
				onClick={handleOperation}
				disabled={code?.length < 4}
			>
				Подтвердить
				<img src="/assets/arrow.svg" alt="arrow" />
			</button>

			<div className={styles.center}>
				<button type="button" className={styles.btnCancel}>
					Отменить оплату
				</button>
			</div>

			<div className={styles.instruction}>
				Пожалуйста, проверьте детали покупки. <br />
				Если данные верны, введите код, направленный на Ваш номер
				телефона.
			</div>

			<footer className={styles.footer}>
				Деятельность Банка ВТБ (ПАО) осуществляется в соответствии с
				генеральной лицензией Банка России №1000
				<div className={styles.phone}>8 800 100-24-24</div>
			</footer>
		</div>
	);
});
