import { Container } from "../../components/Container";
import { Skeleton } from "../../components/Skeleton/Skeleton";
import cn from "classnames";
import { tg } from "../../index";
import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "../../utils";

import styles from "./index.module.sass";
import { BorderedBlock } from "../../components/BorderedBlock";
import { TextInput } from "@technokratos/components-react";
import { Header } from "../../components/Header";

const banks = [
	{
		name: "Тинькофф",
		icon: "/assets/tinkoff.svg",
	},
	{
		name: "Сбербанк",
		icon: "/assets/sber.svg",
	},
	{
		name: "Альфа Банк",
		icon: "/assets/alfa.svg",
	},
	{
		name: "ЮMoney",
		icon: "/assets/umoney.svg",
	},
	{
		name: "ВТБ",
		icon: "/assets/vtb.svg",
	},
];

export const SuccessAddDetails = () => {
	const {
		userStore: {
			operationData,
			operationDone,
			phoneNumber,
			setPhoneNumber,
			currencySign,
		},
	} = useStores();

	//const [id, setId] = useState("");
	const [skeleton, setSkeleton] = useState(false);
	const [showBanks, setShowBanks] = useState(false);
	const [selectedBank, setSelectedBank] = useState(null);

	const disableButton = useMemo(() => {
		return (
			!Boolean(selectedBank) ||
			!Boolean(phoneNumber) ||
			phoneNumber?.length < 14
		);
	}, [selectedBank, phoneNumber]);

	useEffect(() => {
		disableButton && tg?.MainButton.hide();
		!disableButton && tg?.MainButton.show();
	}, [disableButton]);

	useEffect(() => {
		if (phoneNumber?.length > 14) setSkeleton(true);

		if (phoneNumber?.length > 14) {
			setTimeout(() => {
				setSkeleton(false);
				setShowBanks(true);
			}, 1000);
		}
	}, [phoneNumber, confirm]);

	useEffect(() => {
		const id = tg?.initDataUnsafe?.start_param?.split("_")[2];

		const handler = () => {
			tg.HapticFeedback.impactOccurred("heavy");
			operationDone(id, tg?.initDataUnsafe?.query_id).then(() =>
				setPhoneNumber(null)
			);
		};

		tg?.onEvent("mainButtonClicked", handler);

		return () => tg?.offEvent("mainButtonClicked", handler);
	}, []);

	// useEffect(() => {
	// 	const id = tg?.initDataUnsafe?.start_param?.split("_")[2];
	// 	setId(id);
	// }, []);

	return (
		<Container>
			<Header to="/success-add" title="Получение перевода" />

			<div className={styles.label}>Откуда</div>
			<BorderedBlock
				top={<>Кошелек</>}
				bottom={<>{operationData?.sum}</>}
			/>

			<div className={styles.label}>Куда</div>
			<TextInput
				label="Номер телефона"
				mask="+7 000 000 0000"
				placeholder="+7 000 000 0000"
				value={phoneNumber}
				onChange={(e) => setPhoneNumber(e)}
				suffix={
					phoneNumber?.length > 5 && (
						<img src="/assets/beeline.svg" alt="" />
					)
				}
			/>

			{skeleton && <Skeleton width="100%" height={100} />}

			{showBanks && (
				<div className={styles.banks}>
					<div className={styles.banks__wrapper}>
						{banks.map((bank) => (
							<button
								className={cn(
									styles.banks__button,
									selectedBank === bank.name &&
										styles.banks__button_selected
								)}
								onClick={() => setSelectedBank(bank.name)}
							>
								<div className={styles.banks__container}>
									<div className={styles.banks__icon_wrapper}>
										<img
											className={styles.banks__icon}
											src={bank.icon}
											alt="bank-logo"
										/>
									</div>

									<div className={styles.banks__name}>
										{bank.name}
									</div>
									<div className={styles.banks__userName}>
										{tg?.initDataUnsafe?.user?.first_name}{" "}
										{tg?.initDataUnsafe?.user?.last_name}
									</div>
								</div>
							</button>
						))}
					</div>
				</div>
			)}

			<div className={styles.offer}>
				Нажимая «Получить», вы соглашаетесь с условиями{" "}
				<span>публичной оферты</span>
			</div>
		</Container>
	);
};
