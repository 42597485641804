import styles from "../../pages/Transfer/transfer-details.module.sass";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Arrow, ArrowLeft } from "../Icons";
import { tg } from "../../index";

interface Props {
	to: string;
	title: string;
}

export const Header = ({ to, title }: Props) => {
	const navigate = useNavigate();

	return (
		<div className={styles.header} onClick={() => navigate(to)}>
			<ArrowLeft fill={tg?.themeParams?.text_color} />
			<div className={styles.header__title}>{title}</div>
		</div>
	);
};
