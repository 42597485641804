import { MainStore } from "./MainStore";
import { makeAutoObservable } from "mobx";
import {
	createSession,
	getOperations,
	getUserData,
	getUserOperations,
	operation,
	operationsDone,
	OperationType,
	UserRequest,
} from "../services";
import { OperationData, Sum, UserData } from "../types";
import { formatCurrencyToNumber, getTransferCommission } from "../utils";

export type CurrenyType = "₽" | "$" | "€";

export default class UserStore {
	isLoading: boolean = true;
	userData: UserData | null = null;
	sum: Sum = {
		refillSum: null,
		transferSum: null,
	};
	cardNumber: string | null = null;
	phoneNumber: string | null = null;
	operationData: OperationData | null = null;
	currencySign: CurrenyType = "₽";
	userOperations: OperationData[] | null = null;
	authorized = false;

	constructor(public mainStore: MainStore) {
		makeAutoObservable(this);
	}

	setAuthorized = () => (this.authorized = true);

	setIsLoading = (loading: boolean) => (this.isLoading = loading);

	setSum = (key: string, value: number) =>
		(this.sum = { ...this.sum, [key]: value });

	setCardNumber = (number: string) => (this.cardNumber = number);

	setPhoneNumber = (phoneNumber: string) => (this.phoneNumber = phoneNumber);

	setCurrencySign = (sign: CurrenyType) => (this.currencySign = sign);

	setUserOperations = (operations: any) => (this.userOperations = operations);

	createSession = (data: any) => {
		const values = {
			queryId: data?.initDataUnsafe?.query_id,
			user: {
				id: data?.initDataUnsafe?.user?.id,
				firstName: data?.initDataUnsafe?.user?.first_name,
				lastName: data?.initDataUnsafe?.user?.last_name,
				userName: data?.initDataUnsafe?.user?.username,
			},
			receiver: {
				id: data?.initDataUnsafe?.receiver?.id,
				firstName: data?.initDataUnsafe?.receiver?.first_name,
				lastName: data?.initDataUnsafe?.receiver?.last_name,
				userName: data?.initDataUnsafe?.receiver?.username,
			},
			authDate: +data?.initDataUnsafe?.auth_date,
			hash: data?.initDataUnsafe?.hash,
			startParam: data?.initDataUnsafe?.start_param,
		};

		return createSession(values);
	};

	getUserData = ({ id, queryId }: UserRequest) => {
		this.setIsLoading(true);

		return getUserData({ id, queryId })
			.then((res) => {
				this.userData = res.data;
			})
			.finally(() => {
				this.setIsLoading(false);
			});
	};

	getOperations = (id: string, queryId: string) => {
		this.setIsLoading(true);

		return getOperations(id, queryId).then(
			(res) => (this.operationData = res.data)
		);
	};

	operation = (data: any, operationType: OperationType) => {
		const values = {
			senderUserId: data?.initDataUnsafe?.user?.id,
			receiverUserId:
				operationType === OperationType.Refill
					? data?.initDataUnsafe?.user?.id
					: data?.initDataUnsafe?.receiver?.id,
			sum:
				operationType === OperationType.Refill
					? formatCurrencyToNumber(this.sum.refillSum)
					: formatCurrencyToNumber(this.sum.transferSum),
			commission:
				operationType === OperationType.Transfer
					? getTransferCommission(
							formatCurrencyToNumber(this.sum.transferSum)
					  )
					: undefined,
			type: operationType,
			appointment: "назначение",
			queryId: data?.initDataUnsafe?.query_id,
		};

		return operation(values);
	};

	operationDone = (id: string, queryId: string) => {
		return operationsDone(id, queryId);
	};

	getUserOperations = (id: string, queryId: string) => {
		return getUserOperations(id, queryId).then((res) =>
			this.setUserOperations(res.data)
		);
	};
}
