import axios, { AxiosPromise } from "axios";
import { OperationData } from "../types";

axios.defaults.baseURL =
	"https://secret-project.back.cloud.technokratos.com/api/v1";
axios.defaults.headers.get["Content-Type"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";

export const createSession = (data: any) =>
	axios.post(
		"/session",
		{ ...data },
		{
			headers: {
				queryId: data.queryId,
			},
		}
	);

export interface Operation {
	senderUserId: number;
	receiverUserId: number;
	sum: number;
	type: OperationType;
	appointment: string;
	queryId: string;
}

export enum OperationType {
	Transfer = "TRANSFER",
	Refill = "REFILL",
}

export const operation = (data: any) => {
	return axios.post(
		"/operations",
		{ ...data },
		{
			headers: {
				queryId: data.queryId,
				"Content-Type": "application/json",
			},
		}
	);
};

export interface UserRequest {
	id: number;
	queryId: string;
}

export const getUserData = ({ id, queryId }: UserRequest) => {
	return axios.get(`users/${id}`, { headers: { queryId: queryId } });
};

export const getOperations = (
	id: string,
	queryId
): AxiosPromise<OperationData> => {
	return axios.get(`operations/${id}`, { headers: { queryId: queryId } });
};

export const getUserOperations = (
	id: string,
	queryId: string
): AxiosPromise<OperationData> => {
	return axios.get(`operations/users/${id}`, {
		headers: { queryId: queryId },
	});
};

export const operationsDone = (id: string, queryId: string) => {
	return axios.post(
		`operations/${id}/done`,
		{},
		{ headers: { queryId: queryId } }
	);
};

export const walletsDone = (queryId: string) => {
	return axios.post(`wallets/done`, {}, { headers: { queryId: queryId } });
};
