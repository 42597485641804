import { useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import styles from "./transfer.module.sass";
import {
	currencyMask,
	formatCurrencyToNumber,
	formatStringToCurrency,
	useStores,
} from "../../utils";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { tg } from "../../index";
import { TextInput } from "@technokratos/components-react";
import { CurrenyType } from "../../stores";
import { Container } from "../../components/Container";
import { ReceiverAvatar } from "../../components/ReceiverAvatar";
import { Pin } from "../../components/Pin";

interface Currency {
	label: string;
	sign: CurrenyType;
}

export const currencies: Array<Currency> = [
	{
		label: "Рубли",
		sign: "₽",
	},
	{
		label: "Доллары",
		sign: "$",
	},
	{
		label: "Евро",
		sign: "€",
	},
];

export const Transfer = observer(() => {
	const {
		userStore: {
			sum,
			setSum,
			userData,
			setCurrencySign,
			currencySign,
			authorized,
		},
	} = useStores();
	const navigate = useNavigate();

	const [width, setWidth] = useState<number>(32);
	const currencySignRef = useRef<HTMLSpanElement>();

	useEffect(() => {
		const spanWidth = currencySignRef?.current?.offsetWidth;

		spanWidth ? setWidth(spanWidth / 2 + 20) : setWidth(35.5);
	}, [sum?.transferSum]);

	useEffect(() => {
		tg?.MainButton.setParams({
			text: "Перевести",
		});

		tg?.onEvent("mainButtonClicked", () => {
			tg.HapticFeedback.impactOccurred("heavy");
			navigate("/transfer-details");
		});
	}, [navigate]);

	useEffect(() => {
		formatCurrencyToNumber(sum?.transferSum) > 0
			? tg?.MainButton?.show()
			: tg?.MainButton?.hide();
	}, [sum?.transferSum]);

	if (!authorized) {
		return <Pin />;
	}

	return (
		<Container>
			<ReceiverAvatar type="to" />

			<div className={styles.input}>
				<span ref={currencySignRef} className={styles.input__mask}>
					{sum?.transferSum}
				</span>
				<TextInput
					placeholder="0"
					value={sum?.transferSum}
					onChange={(e) => setSum("transferSum", e)}
					mask={currencyMask}
					prefix={
						<div
							className={styles.input__currency}
							style={{ left: `calc(50% - 4px - ${width}px)` }}
						>
							{currencySign}
						</div>
					}
				/>
			</div>

			<div className={styles.currency}>
				{currencies.map((button) => (
					<Button
						theme={
							currencySign === button.sign ? "primary" : "muted"
						}
						onClick={() => setCurrencySign(button.sign)}
					>
						{button.label}
					</Button>
				))}
			</div>

			<div className={styles.balance}>
				<div className={styles.balance__data}>
					<span>Рублей в кошельке</span>

					<span>
						{formatStringToCurrency(userData?.sum)} {currencySign}
					</span>
				</div>

				<button onClick={() => navigate("/refill")}>Пополнить</button>
			</div>
		</Container>
	);
});
