import React, { useCallback, useEffect, useRef, useState } from "react";
import { TextInput } from "@technokratos/components-react";
import { observer } from "mobx-react";
import { formatStringToCurrency, useStores } from "../../utils";
import { tg } from "../../index";
import { Container } from "../../components/Container";
import { ReceiverAvatar } from "../../components/ReceiverAvatar";
import { CurrencyBlock } from "../../components/CurrencyBlock";
import { BorderedBlock } from "../../components/BorderedBlock";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.sass";

export const SuccessAdd = observer(() => {
	const {
		userStore: {
			getOperations,
			operationData,
			createSession,
			operationDone,
			phoneNumber,
			setPhoneNumber,
			isLoading,
			setIsLoading,
			currencySign,
		},
	} = useStores();
	const navigate = useNavigate();

	const [id, setId] = useState(null);

	useEffect(() => {
		tg?.expand();
	}, []);

	useEffect(() => {
		const id = tg?.initDataUnsafe?.start_param?.split("_")[2];
		setId(id);
	}, []);

	useEffect(() => {
		createSession(tg);
	}, []);

	useEffect(() => {
		phoneNumber?.length > 14
			? tg?.MainButton.show()
			: tg?.MainButton.hide();
	}, [phoneNumber]);

	useEffect(() => {
		tg?.MainButton?.setParams({
			text: "Далее",
		});

		const handler = () => {
			tg.HapticFeedback.impactOccurred("heavy");
			navigate("/success-add-details");
		};

		tg?.onEvent("mainButtonClicked", handler);

		return () => tg?.offEvent(handler);
	}, [navigate]);

	useEffect(() => {
		if (id) {
			getOperations(id, tg?.initDataUnsafe?.query_id).then(() =>
				setIsLoading(false)
			);
		}
	}, [id]);

	const inputRef = useRef<HTMLInputElement>();
	const inputRefCb = useCallback((ref) => {
		inputRef.current = ref;
	}, []);

	if (isLoading) {
		return null;
	}

	// if (operationData?.receiver?.userId !== tg?.initDataUnsafe?.user?.id) {
	// 	return (
	// 		<Container>
	// 			<div className={styles.invalidReceiver}>
	// 				Перевод доступен только для получателя.
	// 			</div>
	// 		</Container>
	// 	);
	// }

	if (operationData?.status !== "NEW") {
		return (
			<Container>
				<div className={styles.invalidReceiver}>
					Операция уже проведена.
				</div>
			</Container>
		);
	}

	return (
		<Container>
			<ReceiverAvatar type="from" />

			<CurrencyBlock
				sum={formatStringToCurrency(operationData?.sum)}
				sign={currencySign}
				suffix={<>Для получения перевода введите номер телефона</>}
			/>

			<form
				onSubmit={(event) => {
					event.preventDefault();
					event.stopPropagation();
					inputRef.current?.blur();
				}}
			>
				<TextInput
					inputRef={inputRefCb}
					label="Номер телефона"
					mask="+7 000 000 0000"
					placeholder="+7 000 000 0000"
					value={phoneNumber}
					onChange={(e) => setPhoneNumber(e)}
					suffix={
						phoneNumber?.length > 5 && (
							<img src="/assets/beeline.svg" alt="" />
						)
					}
				/>
			</form>
			<div className={styles.offer}>
				Нажимая «Получить», вы соглашаетесь с условиями{" "}
				<span>публичной оферты</span>
			</div>
		</Container>
	);
});
