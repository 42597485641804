import React, {ReactNode} from "react";

import styles from './index.module.sass'
import {CurrenyType} from "../../stores";

interface Props {
	sign: CurrenyType;
	sum: number | string;
	suffix?: ReactNode
}

export const CurrencyBlock = ({sign, sum, suffix}: Props) => (
	<>
		<div className={styles.sum}>
			<span>{sign}</span> <span>{sum}</span>
		</div>
		
		{suffix && <div className={styles.suffix}><div>{suffix}</div></div>}
	</>
)
