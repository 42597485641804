import React, { useEffect } from "react";
import { WalletAdd } from "./pages/WalletAdd";
import { SuccessAdd } from "./pages/SuccessAdd";
import { observer } from "mobx-react";
import { Home } from "./pages/Home";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Refill } from "./pages/Refill/Refill";
import { Bank } from "./pages/Bank";
import { SendSuccess } from "./pages/SendSuccess";
import { tg } from "./index";
import { useStores } from "./utils";
import { Transfer, TransferDetails } from "./pages/Transfer";
import { RefillDetails } from "./pages/Refill";
import { SuccessAddDetails } from "./pages/SuccessAdd/SuccessAddDetails";

export const App = observer(() => {
	const { userStore } = useStores();
	const navigate = useNavigate();

	useEffect(() => {
		tg?.ready();

		tg?.MainButton.setParams({
			color: "#3390EC",
		});

		if (tg.initDataUnsafe?.start_param !== "success_add") {
			tg?.expand();
		}
	}, []);

	useEffect(() => {
		switch (true) {
			case tg?.initDataUnsafe?.start_param?.includes("request_operation"):
				return navigate("/success-add");
			case tg?.initDataUnsafe?.receiver?.is_bot === false:
				return navigate("/transfer");
			case tg.initDataUnsafe?.start_param === "success_add":
				return navigate("/wallet-add");
			case !tg?.initDataUnsafe:
				return navigate("/");
		}
	}, []);

	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/success-add" element={<SuccessAdd />} />
			<Route
				path="/success-add-details"
				element={<SuccessAddDetails />}
			/>
			<Route path="/refill" element={<Refill />} />
			<Route path="/refill-details" element={<RefillDetails />} />
			<Route path="/wallet-add" element={<WalletAdd />} />
			<Route path="/bank" element={<Bank />} />
			<Route path="/transfer" element={<Transfer />} />
			<Route path="/transfer-details" element={<TransferDetails />} />
		</Routes>
	);
});
