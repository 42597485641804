import {hexToRgb} from "../utils";
import {tg} from "../index";

export const useThemeVariables = () => {
    const themeVariables = {
        buttonBackground: `rgba(${hexToRgb(tg?.themeParams?.link_color)}, 0.06)`
    }

    return {
        themeVariables
    }
}