import styles from "./index.module.sass";
import React, { useEffect, useState } from "react";
import { ArrowRight } from "../Icons";
import { tg } from "../../index";
import { getPhotoUrl, useStores } from "../../utils";
import classNames from "classnames";
import { ReactComponent as IconClear } from "../../assets/icons/icon_clear.svg";
import { Spinner } from "../Spinner";
import { observer } from "mobx-react";

const dots = [0, 1, 2, 3];
const digits = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export const Pin = observer(() => {
	const {
		userStore: { setAuthorized },
	} = useStores();

	const [inputDigits, setInputDigits] = useState([]);

	const onDigit = (event) => {
		tg.HapticFeedback.impactOccurred("medium");

		setInputDigits((prevState) => {
			if (prevState.length < 4) {
				prevState.push(1);
			}
			return [...prevState];
		});
	};

	const onClear = () => {
		tg.HapticFeedback.impactOccurred("medium");

		setInputDigits((prevState) => {
			prevState.pop();
			return [...prevState];
		});
	};

	useEffect(() => {
		if (inputDigits.length >= 4) {
			setTimeout(() => {
				setAuthorized();
			}, 1200);
		}
	}, [inputDigits]);

	if (inputDigits.length >= 4) {
		return (
			<div className={styles.loader}>
				<Spinner />
			</div>
		);
	}

	return (
		<>
			<div className={styles.header}>
				<div className={styles.header__photo}>
					<img
						src={getPhotoUrl(tg?.initDataUnsafe?.user?.photo_url)}
						alt=""
					/>
				</div>

				<div className={styles.header__title}>
					{tg?.initDataUnsafe?.user?.first_name}
				</div>

				<div className={styles.header__arrow}>
					<ArrowRight />
				</div>
			</div>
			<div className={styles.enter}>
				<p>Здравствуйте</p>
				<div className={styles.dots}>
					{dots.map((dot, index) => (
						<div
							className={classNames(styles.dots__item, {
								[styles.dots__item_active]:
									inputDigits.length > index,
							})}
						></div>
					))}
				</div>
			</div>

			<div className={styles.digits}>
				<div className={styles.digits__rows}>
					{digits.map((digit) => (
						<button
							onClick={onDigit}
							className={styles.digits__item}
							key={digit}
						>
							{digit}
						</button>
					))}
					<div className={styles.lostCode}>
						Забыл
						<br /> код
					</div>

					<button onClick={onDigit} className={styles.digits__item}>
						0
					</button>
					{inputDigits.length > 0 && (
						<button
							onClick={onClear}
							className={styles.digits__clear}
						>
							<IconClear />
						</button>
					)}
				</div>
			</div>
		</>
	);
});
